import React from 'react';

import { UserDeleteOutlined } from '@ant-design/icons';
import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerBlackList: IRouter = {
  path: '/customer-and-blacklist/black-list',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'blacklist.sidebar', //translate here for breadcrumb and sidebar
  masterLayout: true,
  icon: <UserDeleteOutlined />,
  permissionCode: PermissionEnum.GuardGroupsView,
};
