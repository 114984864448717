export default {
  'customer.router.name': 'Khách hàng',
  'customer.title': 'Quản lý khách hàng',
  'customer.update': 'Cập nhật khách hàng',
  'customer.name': 'Họ và tên',
  'customer.nickName': 'Tên người dùng',
  'customer.customerName': 'Tên khách hàng',
  'customer.phoneNumber': 'Số điện thoại',
  'customer.fullName': 'Họ và tên',
  'customer.address': 'Địa chỉ',
  'customer.province': 'Tỉnh/Thành phố',
  'customer.district': 'Quận/Huyện',
  'customer.ward': 'Phường/Xã',
  'customer.status': 'Trạng thái hoạt động',
  'customer.info': 'Thông tin khách hàng',

  'ItemWarehouse.tab.name': 'Kho vật phẩm',
  'VouchersWarehouse.tab.name': 'Kho voucher  quy đổi',
  'GiftWarehouse.tab.name': 'Kho quà',
  'ScoreHistory.tab.name': 'Lịch sử tích điểm',
  'RankHistory.tab.name': 'Lịch sử xếp hạng',

  'customer.validate-phone': 'Số điện thoại không hợp lệ',

  'customer.greenCoin': 'Green Coin </br> (Còn lại/tổng tích lũy)',
  'customer.greenCoin.xlsx': 'Green Coin (Còn lại/tổng tích lũy)',
  'customer.greenCoin.used': 'Green Coin đã sử dụng',
  'customer.lastWeekRanking': 'Xếp hạng tuần trước',
  'customer.specificAddress': 'Địa chỉ',
  'customer.currentWeekRanking': 'Xếp hạng tuần hiện tại',
  'customer.overallRanking': 'Xếp hạng Overall',
  'customer.action': 'Hành động',
  'customer.status.param':
    '{status,select, 1 {Đang hoạt động} 2 {Dừng hoạt động} 3 {Đã xoá}  other {Không xác định}}',
  'customer.accumulatedCoins': 'Số coin tích lũy',
  'customer.remainingCoins': 'Số coin sử dụng',
  'customer.usedCoins': 'Số coin đã sử dụng',

  'scoreHistory.device.specificAddress': 'Địa chỉ lắp đặt',
  'scoreHistory.device.name': 'Thiết bị',
  'scoreHistory.time': 'Thời gian',
  'scoreHistory.invalidQuantity': 'Số lượng chai brand khác',
  'scoreHistory.validQuantity': 'Số lượng chai là Aqua',
  'scoreHistory.score': 'Số điểm đạt được',
  'scoreHistory.card.score': 'Tổng số điểm đạt được',
  'scoreHistory.card.invalidQuantity': 'Tổng số chai brand khác',
  'scoreHistory.card.validQuantity': 'Tổng số chai Aqua',

  'giftWarehouse.status.type': '{status,select, 1 {Hiện vật} 2 {Voucher} other {Không xác định}}',
  'giftWarehouse.status.source':
    '{status,select, 1 {Admin trao tặng} 2 {Bảng xếp hạng tuần} other {Không xác định}}',
  'giftWarehouse.total-gift': 'Tổng số quà',
  'giftWarehouse.rank-gift': 'Quà xếp hạng',
  'giftWarehouse.different-gift': 'Quà khác',

  'giftWarehouse.awardName': 'Loại giải',
  'giftWarehouse.rank': 'Xếp hạng',
  'giftWarehouse.code': 'Mã quà tặng',
  'giftWarehouse.name': 'Tên quà tặng',
  'giftWarehouse.type': 'Loại quà tặng',
  'giftWarehouse.coin': 'Số Green Coin',
  'giftWarehouse.sourceGift': 'Nguồn quà tặng',
  'giftWarehouse.createdAt': 'Thời gian',
  'giftWarehouse.giftRevokingStatus': 'Trạng thái quà tặng',
  'giftWarehouse.status.giftRevokingStatus':
    '{status,select, 1 {Đã thu hồi} 2 {Đã phát} other {Unknown}}',
  'giftWarehouse.action': 'Hành động',
  'giftWarehouse.type-artifact': 'Hiện vật',
  'giftWarehouse.type-voucher': 'Voucher',
  'giftWarehouse.source-awardSource': 'Admin trao tặng',
  'giftWarehouse.source-rankSource': 'Bảng xếp hạng tuần',
  'giftWarehouse.source-Item-offers': 'Ưu đãi vật phẩm',
  'giftWarehouse.information': 'Thông tin quà tặng',

  'customers.createdAt.time': 'Thời gian tạo',

  'itemWarehouse.action': 'Hành động',
  'itemWarehouse.item.thumbnail': 'Hình ảnh',
  'itemWarehouse.item.code': 'Mã vật phẩm',
  'itemWarehouse.item.name': 'Tên vật phẩm',
  'itemWarehouse.item.group.name': 'Nhóm vật phẩm',
  'itemWarehouse.item.quantity': 'Green Coin',
  'itemWarehouse.item.collection.name': 'Bộ sưu tập',
  'itemWarehouse.item.type': 'Loại vật phẩm',
  'itemWarehouse.equippingStatus': 'Trạng thái',
  'itemWarehouse.createdAt': 'Thời gian mua',
  'itemWarehouse.status.type':
    '{status,select, 1 {Loại bình thường} 2 {Loại bí ẩn} other {Không xác định}}',
  'itemWarehouse.status.equippingStatus':
    '{status,select, 1 {Đang trang bị} 2 {Không trang bị} 3 {Đã thu hồi} other {Không xác định}}',
  'itemWarehouse.status.normal': 'Loại bình thường',
  'itemWarehouse.status.mystery': 'Loại bí ẩn',
  'itemWarehouse.status.equipping': 'Đang trang bị',
  'itemWarehouse.status.notEquipping': 'Không trang bị',
  'itemWarehouse.status.revoked': 'Đã thu hồi',
  'itemWarehouse.card.itemQuantity': 'Tổng số vật phẩm',
  'itemWarehouse.card.coinQuantity': 'Tổng số green coin đã mua vật phẩm',
  'itemWarehouse.information': 'Thông tin vật phẩm',
  'customer.title.recall': 'Thu hồi vật phẩm',
  'customer.content.recall': 'Bạn có chắc chắn muốn thu hồi vật phẩm này không?',
  'customer.title.gift-recall': 'Thu hồi quà tặng',
  'customer.content.gift-recall': 'Bạn có chắc chắn muốn thu hồi quà tặng này không?',

  'vouchersWarehouse.price': 'Điểm quy đổi (Green Coin)',
  'vouchersWarehouse.name': 'Loại voucher',
  'vouchersWarehouse.receivingTime': 'Thời gian nhận',
  'vouchersWarehouse.usageStatus': 'Trạng thái sử dụng',
  'vouchersWarehouse.totalVoucher': 'Tổng số voucher',
  'vouchersWarehouse.totalGreencoin': 'Tổng số điểm quy đổi',

  'vouchersWarehouse.used': 'Đã sử dụng',
  'vouchersWarehouse.unUsed': 'Chưa sử dụng',
  'vouchersWarehouse.normal': 'Loại thường',
  'vouchersWarehouse.prioritize': 'Loại ưu tiên',
  'vouchersWarehouse.information': 'Thông tin kho voucher',
  'Green.Coin': 'Green Coin',
  'vouchersWarehouse.amountCoin': 'Số tiền/điểm nhận được',
  'vouchersWarehouse.phoneNumber': 'Số điện thoại nhận',
  'vouchersWarehouse.voucherPartner': 'Đối tác',

  'rankHistory.numberWeeksRanked': 'Tổng số tuần đã xếp hạng',
  'rankHistory.awardWinningRatings': 'Số xếp hạng đạt giải',
  'rankHistory.title.closingTime': 'Thời gian',
  'rankHistory.rank': ' Xếp hạng',
  'rankHistory.awardName': 'Loại giải',
  'rankHistory.coin': 'Số green coin xếp hạng',
  'rankHistory.week.id': 'Số tuần',
  'rankHistory.closingTime': 'Thời gian chốt thưởng',
  'customer.avatarPicture': 'AVT',
  'customer.userName': 'Tên đăng nhập',
  'customer.createdAt': 'Ngày tạo',
  'customer.currentWeekRanking.notnull': 'Có tích điểm tuần này',
  'customer.currentWeekRanking.null': 'Chưa tích điểm tuần này',
  'customer.currentWeekRanking.action': 'Hoạt động tuần này',

  // update blacklist 10/10/2024
  'customer.not-ban': 'Chưa bị hạn chế',
  'customer.banned': 'Từng bị hạn chế',
  'customer.banning': 'Đang bị hạn chế',
  'customer.status.ban': 'Trạng thái hạn chế',
  'customer.count-banned': 'Lần bị hạn chế',
  'customer.status-banned': 'Trạng thái hạn chế',
  'blacklist.sidebar': 'Hạn chế',
  'customer.impact-point-history': 'Lịch sử tác động điểm',
  'impactPointHistory.action': 'Hành động',
  'impactPointHistory.content': 'Nội dung',
  'impactPointHistory.effectTime': 'Thời gian tác động',
  'impactPointHistory.effectTarget': 'Đối tượng tác động',
  'customer.restricted-information': 'Thông tin hạn chế',
  'customer.restricted-history': 'Lịch sử hạn chế',
  'customer.restricted-history.pl': 'Tên item/ Mã vật phẩm',
  'restrictedInformation.action': 'Hành động',
  'restrictedInformation.effectTime': 'Thời gian tác động',
  'customer.restricted-information-current': 'Thông tin hạn chế hiện tại',
  'restrictedInformation.group': 'Nhóm hạn chế',
  'restrictedInformation.applyTime': 'Thời gian áp dụng',
  'restrictedInformation.btn.cancel': 'Bỏ hạn chế',
  'restrictedInformation.btn.change': 'Thay đổi nhóm',
  'restrictedInformation.popup.cancel.title': 'Xác nhận bỏ hạn chế',
  'restrictedInformation.popup.cancel.content':
    'Hạn chế đã được áp dụng cho người dùng, bạn có chắc chắn muốn bỏ hạn chế này không?',
  'restrictedInformation.modal.add.title': 'Hạn chế người dùng',
  'restrictedInformation.modal.edit.title': 'Thay đổi nhóm hạn chế',
  'restrictedInformation.group.pl': 'Chọn nhóm hạn chế',
  'restrictedInformation.modal.info.title': 'Thông tin hạn chế khi thay đổi',
  'restrictedInformation.changeTime': 'Thời gian thay đổi',

  'restrictedInformation.btn.add': 'Hạn chế',
  'customer-and-black-list.sidebar': 'Khách hàng',
  'blackList.group.code': 'Mã nhóm',
  'blackList.group.name': 'Tên nhóm',
  'blackList.limit': 'Số lượng giới hạn',
  'blackList.members': 'Người dùng đang bị hạn chế',
  'restricted.modal.add.title': 'Thêm nhóm hạn chế',
  'restricted.modal.edit.title': 'Cập nhật nhóm hạn chế',
  'restricted.modal.info.title': 'Chi tiết nhóm hạn chế',
  'restricted.code': 'Mã nhóm',
  'restricted.code.pl': 'Nhập mã nhóm hạn chế',
  'restricted.name': 'Tên nhóm',
  'restricted.name.pl': 'Nhập tên nhóm hạn chế',
  'restrictedInformation.type': 'Giới hạn',
  'restrictedInformation.type.1': 'Giói hạn voucher quy đổi',
  'restricted.all': 'Tất cả',
  'restricted.momo': 'Top-up Momo',
  'restricted.maximun': 'Tối đa',
  'restricted.maximun.pl': 'Số voucher',
  'restricted.delete.title': 'Xác nhận nhóm xóa hạn chế',
  'restricted.delete.content': 'Bạn có muốn xóa nhóm hạn chế đã chọn?',
  'restricted.account': 'Tên đăng nhập',
  'restricted.user': 'Tên người dùng',
  'restricted.phone': 'Số điện thoại',
  'restricted.rest-time': 'Thời gian còn lại',
  'restricted.typeVoucher': 'Loại voucher',
  'restricted.limitTime': 'Thời gian giới hạn',
  'restricted.select.hour': 'Theo giờ',
  'restricted.select.day': 'Theo ngày',
  'restricted.select.week': 'Theo tuần',
  'restricted.select.month': 'Theo tháng',
  'restricted.select.year': 'Theo năm',
  'restricted.select.hour.pl': 'Nhập số giờ',
  'restricted.select.day.pl': 'Nhập số ngày',
  'restricted.select.week.pl': 'Nhập số tuần',
  'restricted.select.month.pl': 'Nhập số tháng',
  'restricted.select.year.pl': 'Nhập số năm',
  'restricted.pageList.delete.title': 'Xác nhận bỏ hạn chế',
  'restricted.pageList.delete.content':
    'Hạn chế đã được áp dụng cho người dùng, bạn có chắc chắn muốn bỏ hạn chế này không?',
  'common.removeRestricted': 'Bỏ hạn chế',
  'restricted.modal.add.user': 'Hạn chế người dùng',
  'routerBlackListPageList.name': 'Danh sách người dùng bị hạn chế',
  'restricted.select-user': 'Chọn người dùng',
  'restricted.result-table': 'Kết quả đang được chọn',
  'restricted.select-user.validate': 'Vui lòng chọn người dùng',
  'blacklist.sidebar-list': 'Danh sách hạn chế',
  'restricted.empty': 'Trống',

  'coinTransactionSource.1': 'Thu thập chai',
  'coinTransactionSource.2': 'Share hình',
  'coinTransactionSource.3': 'Đổi voucher',
  'coinTransactionSource.5': 'Mua lượt swap',
  'coinTransactionSource.6': 'Cập nhật thủ công',
  'coinTransactionSource.7': 'Mua vật phẩm',
  'coinTransactionSource.8': 'Đạt xếp hạng',
  'coinTransactionSource.9': 'Mua vật phẩm từ game raising pet',
  'coinTransactionSource.10': 'Mua voucher quy đổi',
  'coinTransactionSource.undefined': 'Không xác định',
  'impactPointHistory.point': 'điểm',
  'impactPointHistory.customer': 'Khách hàng',
  'restricted.effectTime.required': 'Nhập thời gian',
  'restrictedInformation.type.pl': 'Chọn giới hạn',

  'restricted.modal.add.title.sub': 'Thông tin nhóm',
  'restricted.hour': 'tiếng',
  'restricted.day': 'ngày',
  'restricted.week': 'tuần',
  'restricted.month': 'tháng',
  'restricted.year': 'năm',
  'restricted.title': 'loại đang bị giới hạn',
  'restricted.desc.type': 'Loại voucher',
  'restricted.voucher.number': 'Số voucher được nhận',
  'restricted.limit': 'tối đa',
  'restricted.time': 'Thời gian',
  'restricted.in': 'trong',
  'restricted.form.limit': 'Giới hạn',
  'restricted.add': 'Thêm giới hạn',
  'featureRestrictions.required': 'Vui lòng nhập số voucher',
  'featureGuardTracking.status.active': 'Hạn chế',
  'featureGuardTracking.status.disabled': 'Bỏ hạn chế',
  'featureGuardTracking.status.update': 'Chỉnh sửa',
  'featureRestrictions.validate': 'Số voucher phải là số tự nhiên lớn hơn hoặc bằng 0',
  'limitTime.validate': 'Thời gian giới hạn là số tự nhiên lớn hơn 0',

  'restrictedInformation.group.icon': 'Danh sách người dùng bị hạn chế',
};
