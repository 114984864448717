import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerPageAddBlackList: IRouter = {
  path: '/customer-and-blacklist/black-list/:type',
  loader: React.lazy(() => import('./index')),
  exact: true,
  masterLayout: true,
  permissionCode: [PermissionEnum.GuardGroupsCreate],
};

export const routerPageEditBlackList: IRouter = {
  path: '/customer-and-blacklist/black-list/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  masterLayout: true,
  permissionCode: [PermissionEnum.GuardGroupsViewDetail, PermissionEnum.GuardGroupsUpdate],
};
