import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerBlackListPageList: IRouter = {
  path: '/customer-and-blacklist/black-list/page-list/:guardGroupId/:nameGuardGroup',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'routerBlackListPageList.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.GuardGroupsView,
};
