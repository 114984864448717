export default {
  'roles.title': 'Quản lý vai trò',
  'roles.name': 'Tên vai trò',
  'roles.description': 'Mô tả vai trò',
  'roles.lastUpdate': 'Cập nhật lần cuối',
  'roles.rolePermissions': 'Quyền',
  'roles.permissionCodes': 'Quyền',
  'roles.createdAt': 'Ngày tạo',
  'roles.action': 'Hành động',
  'Per.Users.Create': 'Thêm user',
  'Per.Users.Delete': 'Xoá user',
  'Per.Users.Edit': 'Sửa user',
  'Per.Users.Export': 'Xuất user',
  'Per.Users.View': 'Xem user',
  'roles.create': 'Tạo vai trò',
  'roles.information': 'Thông tin vai trò',
  'roles.update': 'Chỉnh sửa vai trò',
  'Mes.Users.Role.UpdateSuccessfully': 'Cập nhật vai trò thành công',
  'Mes.Roles.Add.Successfully': 'Thêm vai trò thành công',

  'roles.createdBy': 'Tạo bởi',
  'roles.createDateTime': 'Ngày tạo',
  'roles.internalComment': 'Ghi chú',
  'roles.isActive': 'Trạng thái hoạt động',
  'roles.delete': 'Xoá vai trò',
  'roles.content.delete': 'Bạn có chắc chắn muốn xóa vai trò này không?',
  'roles.add.name': 'Thêm mới vai trò',
  'roles.info.name': 'Thông tin vai trò',
  'roles.edit.name': 'Cập nhật vai trò',

  'roles.code': 'Mã vai trò',
  'roles.numberOfAssign': 'Số tài khoản được phân quyền',

  'roles.delete.title': 'Xoá vai trò',
  'roles.delete.content': 'Bạn có chắc chắn muốn xóa vai trò này không?',
  ///////Role
  'role.name': 'Vai trò',
  'role.list': 'Danh sách vai trò',
  'role.action': 'Hành động',
  'role.delete-title': 'Xóa vai trò',
  'role.delete-content': 'Bạn có muốn xóa vai trò đã chọn ?',
  'role.information': 'Thông tin vai trò',
  'role.update': 'Chỉnh sửa vai trò',
  'role.create': 'Thêm vai trò mới',

  Users: 'Người dùng',
  UsersView: 'Xem người dùng',
  UsersViewDetail: 'Xem chi tiết người dùng',
  UsersCreate: 'Tạo người dùng',
  UsersUpdate: 'Cập nhật người dùng',
  UsersDelete: 'Xóa người dùng',
  Roles: 'Vai trò',
  RolesView: 'Xem vai trò',
  RolesViewDetail: 'Xem chi tiết vai trò',
  RolesCreate: 'Tạo vai trò',
  RolesUpdate: 'Cập nhật vai trò',
  RolesDelete: 'Xóa vai trò',
  Collections: 'Bộ sưu tập',
  CollectionsCreate: 'Tạo bộ sưu tập',
  CollectionsDelete: 'Xóa bộ sưu tập',
  CollectionsView: 'Xem bộ sưu tập',
  CollectionsViewDetail: 'Xem chi tiết bộ sưu tập',
  CollectionsUpdate: 'Cập nhật bộ sưu tập',
  Customers: 'Khách hàng',
  CustomersCreate: 'Tạo khách hàng',
  CustomersDelete: 'Xóa khách hàng',
  CustomersView: 'Xem khách hàng',
  CustomersViewDetail: 'Xem chi tiết khách hàng',
  CustomersUpdate: 'Cập nhật khách hàng',
  Devices: 'Thiết bị',
  DevicesCreate: 'Tạo thiết bị',
  DevicesDelete: 'Xóa thiết bị',
  DevicesView: 'Xem thiết bị',
  DevicesViewDetail: 'Xem chi tiết thiết bị',
  DevicesUpdate: 'Cập nhật thiết bị',
  DevicesCollectCreate: 'Thu gom',
  DevicesCollectView: 'Xem lịch sử thu gom thùng chứa',
  DevicesFullContainerHistoryView: 'Xem lịch sử đầy thùng',
  Events: 'Chương trình vật phẩm',
  EventsCreate: 'Tạo chương trình vật phẩm',
  EventsView: 'Xem chương trình vật phẩm',
  EventsViewDetail: 'Xem chi tiết chương trình vật phẩm',
  EventsUpdate: 'Cập nhật chương trình vật phẩm',
  EventsDelete: 'Xóa chương trình vật phẩm',
  Items: 'Vật phẩm',
  ItemsCreate: 'Tạo vật phẩm',
  ItemsDelete: 'Xóa vật phẩm',
  ItemsView: 'Xem vật phẩm',
  ItemsViewDetail: 'Xem chi tiết vật phẩm',
  ItemsUpdate: 'Cập nhật vật phẩm',
  OtherGifts: 'Quà khác',
  OtherGiftsCreate: 'Tạo quà khác',
  OtherGiftsDelete: 'Xóa quà khác',
  OtherGiftsView: 'Xem quà khác',
  OtherGiftsUpdate: 'Cập nhật quà khác',
  OtherGiftsViewDetail: 'Xem chi tiết quà khác',
  OtherGiftsTransfer: 'Chuyển quà khác',
  OtherGiftsDistribute: 'Phát quà khác',
  RankingGifts: 'Quà xếp hạng',
  RankingGiftsCreate: 'Tạo quà xếp hạng',
  RankingGiftsDelete: 'Xóa quà xếp hạng',
  RankingGiftsView: 'Xem quà xếp hạng',
  RankingGiftsViewDetail: 'Xem chi tiết quà xếp hạng',
  RankingGiftsUpdate: 'Cập nhật quà xếp hạng',
  RankingGiftsTransfer: 'Chuyển quà xếp hạng',
  Settings: 'Thời gian chốt bảng xếp hạng',
  SettingsUpdate: 'Cập nhật thời gian chốt bảng xếp hạng',
  SettingsView: 'Xem thời gian chốt bảng xếp hạng',
  Errors: 'Lỗi',
  ErrorsUpdate: 'Cập nhật lỗi',
  ErrorsView: 'Xem lỗi',
  ErrorsViewDetail: 'Xem chi tiết lỗi',
  OtherGiftVouchers: 'Voucher quà khác',
  OtherGiftsVouchersCreate: 'Tạo voucher cho quà khác',
  OtherGiftsVouchersDelete: 'Xóa voucher cho quà khác',
  OtherGiftsVouchersView: 'Xem voucher cho quà khác',
  OtherGiftsVouchersUpdate: 'Cập nhật voucher cho quà khác',
  RankingGiftVouchers: 'Voucher quà xếp hạng',
  RankingGiftsVouchersCreate: 'Tạo voucher cho quà xếp hạng',
  RankingGiftsVouchersDelete: 'Xóa voucher cho quà xếp hạng',
  RankingGiftsVouchersView: 'Xem voucher cho quà xếp hạng',
  RankingGiftsVouchersUpdate: 'Cập nhật voucher cho quà xếp hạng',
  ItemGroups: 'Nhóm vật phẩm',
  ItemGroupsCreate: 'Tạo nhóm vật phẩm',
  ItemGroupsDelete: 'Xóa nhóm vật phẩm',
  ItemGroupsView: 'Xem nhóm vật phẩm',
  ItemGroupsViewDetail: 'Xem chi tiết nhóm vật phẩm',
  ItemGroupsUpdate: 'Cập nhật nhóm vật phẩm',
  ItemsChangeStatus: 'Thay đổi trạng thái vật phẩm',
  ItemsPurchaseHistoriesView: 'Xem lịch sử mua vật phẩm',
  CustomersPurchaseHistoriesRevoke: 'Thu hồi vật phẩm khách hàng',
  CustomersGiftsRevoke: 'Thu hồi quà khách hàng',
  CustomersCollectionHistoriesView: 'Xem lịch sử tích điểm khách hàng',
  CustomersVoucherStoragesView: 'Xem lịch sử voucher khách hàng',
  CustomersPurchaseHistoriesView: 'Xem lịch sử mua hàng khách hàng',
  CustomersLeaderboardHistoriesView: 'Xem lịch sử xếp hạng khách hàng',
  CustomersGiftStoragesView: 'Xem lịch sử quà khách hàng',
  VoucherTypesViewDetail: 'Xem chi tiết voucher quy đổi',
  VoucherTypes: 'Voucher quy đổi',
  VoucherTypesCreate: 'Tạo voucher quy đổi',
  VoucherTypesDelete: 'Xóa voucher quy đổi',
  VoucherTypesView: 'Xem voucher quy đổi',
  VoucherTypesUpdate: 'Cập nhật voucher quy đổi',
  DashboardsView: 'Xem trang chủ',
  Dashboards: 'Trang chủ',
  OverallView: 'Xem bảng xếp hạng Overall',
  Overall: 'Bảng xếp hạng Overall',
  WeeklyView: 'Xem bảng xếp hạng tuần',
  Weekly: 'Bảng xếp hạng tuần',
  WeeklyDistribute: 'Phát quà xếp hạng thủ công',
  DevicesCollectionHistoriesView: 'Xem lịch sử thu thập chai',
  Reports: 'Báo cáo',
  ReportsView: 'Xem báo cáo tổng quan',
  DeviceGroupsDelete: 'Xoá nhóm thiết bị',
  DeviceGroupsView: 'Xem nhóm thiết bị',
  DeviceGroupsCreate: 'Tạo nhóm thiết bị',
  DeviceGroupsUpdate: 'Cập nhật nhóm thiết bị',
  DeviceGroupsViewDetail: 'Xem chi tiết nhóm thiết bị',
  DeviceGroups: 'Nhóm thiết bị',

  CustomersCoinTransactionView: 'Xem lịch sử tác động điểm',
  CustomersGuardGroupParticipantsView: 'Xem thông tin hạn chế',
  CustomersGuardGroupParticipantsDelete: 'Bỏ hạn chế',
  CustomersGuardGroupParticipantsCreate: 'Thêm nhóm hạn chế',
  CustomersGuardGroupsViewActive: 'Xem thông tin hạn chế hiện tại',
  CustomersGuardGroupParticipantsUpdate: 'Cập nhật hạn chế hiện tại',

  GuardGroups: 'Hạn chế',
  GuardGroupsCreate: 'Thêm nhóm hạn chế',
  GuardGroupsDelete: 'Xóa nhóm hạn chế',
  GuardGroupsUpdate: ' Cập nhật nhóm hạn chế',
  GuardGroupsView: 'Xem danh sách hạn chế',
  GuardGroupsViewDetail: 'Xem chi tiết nhóm hạn chế',

  Guards: 'Giới hạn',
  GuardsCreate: 'Thêm nhóm giới hạn',
  GuardsDelete: 'Xóa nhóm giới hạn',
  GuardsUpdate: ' Cập nhật nhóm giới hạn',
  GuardsView: 'Xem danh sách giới hạn',
  GuardsViewDetail: 'Xem chi tiết nhóm giới hạn',
};
