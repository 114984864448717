import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerCustomer: IRouter = {
  path: '/customer-and-blacklist/customer',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'customer.router.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  icon: <UserOutlined />,
  permissionCode: PermissionEnum.CustomersView,
};
