import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerCustomerInfo: IRouter = {
  path: '/customer-and-blacklist/customer/info/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'customer.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: [
    PermissionEnum.CustomersViewDetail,
    // PermissionEnum.CustomersPurchaseHistoriesView,
    PermissionEnum.CustomersVoucherStoragesView,
    PermissionEnum.CustomersGiftStoragesView,
    PermissionEnum.CustomersCollectionHistoriesView,
    PermissionEnum.CustomersLeaderboardHistoriesView,
    PermissionEnum.CustomersCoinTransactionView,
    PermissionEnum.CustomersGuardGroupParticipantsView,
  ],
  generatePath(id: string, listPermissions: any) {
    const permissionTabs = [
      // PermissionEnum.CustomersPurchaseHistoriesView,
      PermissionEnum.CustomersVoucherStoragesView,
      PermissionEnum.CustomersGiftStoragesView,
      PermissionEnum.CustomersCollectionHistoriesView,
      PermissionEnum.CustomersLeaderboardHistoriesView,
      PermissionEnum.CustomersCoinTransactionView,
      PermissionEnum.CustomersGuardGroupParticipantsView,
    ];
    if (permissionTabs.find(item => listPermissions.includes(item))) {
      return `/customer-and-blacklist/customer/info/${id}?tab=${permissionTabs.find(item =>
        listPermissions.includes(item),
      )}`;
    }
    return `/customer-and-blacklist/customer/info/${id}`;
  },
  widthFull: true,
};
