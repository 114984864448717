import React from 'react';

import { TeamOutlined } from '@ant-design/icons';
import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';
import { routerBlackList } from '@view/BlackList/router';
import { routerCustomer } from '@view/Customer/router';

export const routerCustomerAndBlacklist: IRouter = {
  path: null,
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'customer-and-black-list.sidebar', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <TeamOutlined />,
    activePath: '/customer-and-blacklist',
  },
  permissionCode: [PermissionEnum.CustomersView, PermissionEnum.GuardGroupsView],
  routes: [routerCustomer, routerBlackList],
};
